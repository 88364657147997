(function(i, s, o, g, r, a, m) {
  i['GoogleAnalyticsObject'] = r;
  i[r] = i[r] || function() {
    (i[r].q = i[r].q || []).push(arguments)
  }, i[r].l = 1 * new Date();
  a = s.createElement(o),
    m = s.getElementsByTagName(o)[0];
  a.async = 1;
  a.src = g;
  m.parentNode.insertBefore(a, m)
})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

ga('create', 'UA-9716951-3', 'auto');
ga('send', 'pageview');
if (gaEcommerce) {
  ga('require', 'ec');

  if (gaRevenue.length > 0 && gaOrderId.length > 0) {
    ga('ec:setAction', 'purchase', {
      'id': gaOrderId,
      'revenue': gaRevenue
    });
  }
}